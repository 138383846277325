<template>
  <div class="pagination" v-if="itemsCount > 0">
		<button
			type="button"
			:class="['btn__pagination', {'btn__white': currentPage > 1}]"
			@click="$emit('prevPage')"
			:disabled="currentPage <= 1"
		>
		<span v-if="btnType==='word'">
			Назад
		</span>
		<icon-arrow v-else-if="btnType === 'arrows'" direction="left" fill="var(--dark-blue)"/>
		</button>

		<strong>{{ currentPage }}/{{ pagesCount }}</strong>

		<button
			type="button"
			:class="['btn__pagination', {'btn__white': currentPage == pagesCount + 1 , 'btn__blue': currentPage != pagesCount}]"
			@click="$emit('nextPage')"
			:disabled="currentPage == pagesCount"
		>
		<span v-if="btnType === 'word'">
			Вперед
		</span>
		<icon-arrow v-else-if="btnType === 'arrows'" direction="right"/>
		</button>
	</div>
</template>

<script>
import IconArrow from '@/assets/svg/IconArrow.vue';
export default {
	name: 'PaginationPages',
	components: { IconArrow },
	props: {
		itemsCount: {
			type: Number,
			default: 0
		},
		currentPage: {
			type: Number,
			default: 1
		},
		pagesCount: {
			type: Number,
			default: 1,
		},
		btnType: {
			type: String,
			default: 'letters',
			validate: (v) => ['word', 'arrows'].includes(v)
		}
	}
}
</script>
