<template>
	<main class="vcc-main vcc-main__kb">
		<h1 class="visually-hidden">База знаний</h1>
		<h2 class="heading heading__hr hr__bold">{{getBusinessTypeName || 'Материалы'}}</h2>
		<section class="materials-main">
			<section class="kb--aside">
				<nav class="kb--nav">
					<ul class="kb-nav--list back" v-if="filterIsOn">
						<li><router-link :to="{name: 'BusinessTypes'}" class="link__btn btn__blue">Назад</router-link></li>
					</ul>
					<ul class="kb-nav--list" v-else>
						<li><router-link :to="{name: 'Materials'}" class="link__menu">Материалы</router-link></li>
						<li><router-link :to="{name: 'Rlas'}" class="link__menu">Нормативно-правовые акты</router-link></li>
						<li><router-link :to="{name: 'BusinessTypes'}" class="link__menu">Виды деятельности</router-link></li>
					</ul>
				</nav>
				<small-email-form type="materials"/>
			</section>
			<section class="list-wth-filters">
				<section class="search-section">
					<form @submit.prevent="">
						<div class="input-wrapper">
							<label for="webinars-search" class="label__input">Поиск по названию</label>
							<input type="search" name="webinars-search" id="webinars-search" class="input" placeholder="Начните вводить название" v-model="search_req" list="webinars-titles">
						</div>
					</form>
					<fieldset class="filters-wrapper filters-wrapper__materials" v-show="true">
						<div class="filters-wrapper--checkboxes">
							<input type="checkbox" name="filter_chkbx" id="all" class="filter-checkbox visually-hidden" :value="null" @click="filter.filter_by = []" :checked="filter.filter_by.length<1">
							<label class="link__btn btn__white btn__filter" for="all" @click="atStartPage">Все материалы</label>
						</div>
						<div class="filters-wrapper--checkboxes">
							<input type="checkbox" name="filter_chkbx" id="analytics" class="filter-checkbox visually-hidden" v-model="filter.filter_by" value="Анализ изменений в законодательстве">
							<label class="link__btn btn__white btn__filter" for="analytics" @click="atStartPage">Анализ изменений в законодательстве</label>
						</div>
						<div class="filters-wrapper--checkboxes">
							<input type="checkbox" name="filter_chkbx" id="methodukaz" class="filter-checkbox visually-hidden" v-model="filter.filter_by" value="Методические указания">
							<label class="link__btn btn__white btn__filter" for="methodukaz" @click="atStartPage">Методические указания</label>
						</div>
						<div class="filters-wrapper--checkboxes">
							<input type="checkbox" name="filter_chkbx" id="methodrec" class="filter-checkbox visually-hidden" v-model="filter.filter_by" value="Методические рекомендации">
							<label class="link__btn btn__white btn__filter" for="methodrec" @click="atStartPage">Методические рекомендации</label>
						</div>
						<div class="filters-wrapper--checkboxes">
							<input type="checkbox" name="filter_chkbx" id="articles" class="filter-checkbox visually-hidden" v-model="filter.filter_by" value="Статьи и мнения экспертов">
							<label class="link__btn btn__white btn__filter" for="articles" @click="atStartPage">Статьи и мнения экспертов</label>
						</div>
					</fieldset>
					<section class="sorting-section">
						<hr class="hr__blue">
						<div class="sorting--info">
							<dl class="dl__theme sorting-section--dl">
								<dt>Соответствует запросу</dt>
								<dd><b>{{filteredItems.length}}</b></dd>
							</dl>
							<dl class="dl__theme sorting-section--dl">
								<dt>Всего в базе</dt>
								<dd><b>{{getMaterials.length}}</b></dd>
							</dl>
							<dl class="dl__theme dl--filter sorting-section--dl">
								<dt><b>Сортировать</b></dt>
								<dd>
									<div class="filter-group">
										<input type="radio" name="order" id="asc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="false">
										<label for="asc" class="sorting-by">с начала</label>
									</div>
									<div class="filter-group">
										<input type="radio" name="order" id="desc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="true">
										<label for="desc" class="sorting-by">с конца</label>
									</div>
									<div :class="['filter-group', {'chrome-date': isChrome }]">
										<input type="date" name="sort-date" id="filter-date" :class="['sort-date', 'date-visually-hidden', 'visually-hidden', {'date-not-empty': filter.date != null}]" v-model="filter.date">
										<label for="filter-date" class="sorting-by">
											по дате
											<svg width="8" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 .66a.57.57 0 0 1-.21.45L4.36 3.87a.57.57 0 0 1-.73 0L.21 1.01A.57.57 0 0 1 .94.13L4 2.7 7.06.22A.57.57 0 0 1 8 .66Z" fill="#9C9D9D"/></svg>
										</label>
									</div>
								</dd>
							</dl>
						</div>
					</section>
				</section>
				<ul class="kb--list" v-if="filterIsOn">
					<li v-for="material, key in paginatedItems" :key="'material-'+key">
						<vcc-kb-card :card-info="material" card-type="material" />
					</li>
				</ul>
				<ul class="kb--list" v-else-if="filteredItems.length > 0">
					<li v-for="material, key in paginatedItems" :key="'material-'+key">
						<vcc-kb-card :card-info="material" card-type="material" />
					</li>
				</ul>
				<div class="no-webinars" v-else>
					<h4>По вашему запросу не нашлось материалов</h4>
					<p>Попробуйте применить другие фильтры или другой запрос.</p>
				</div>
				<div class="no-webinars" v-if="filteredItems.length <= 0 && filterIsOn">
					<h4>По вашему запросу не нашлось материалов</h4>
					<p>Попробуйте применить другие фильтры или другой запрос.</p>
				</div>
				<pagination-pages
					:current-page.sync="currentPage"
					:items-count="filteredItems.length"
					:pages-count="pageCount"
					:btn-type="isMobile ? 'arrows' : 'word'"
					@prevPage="prevPage"
					@nextPage="nextPage"
				/>
			</section>
		</section>
	</main>
</template>

<script>
import deviceWidth from '@/mixins/deviceWidth.js'
import SmallEmailForm from '@/components/forms/SmallEmailForm.vue'
import PaginationPages from '@/components/common/PaginationPages.vue'
import KbCard from '@/components/cards/KbCard.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
	data() {
		return {
			items_per_page: 9,
			page_number: this.page || 1,
			search_req: null,
			filter: {
				date : null,
				sort_by: true,
				filter_by: []
			},
		}
	},
	mixins: [deviceWidth],
	props:['page', 'business_type'],
	components: {
		'vcc-kb-card': KbCard,
		'small-email-form': SmallEmailForm,
		PaginationPages,
	},
	computed: {
		...mapGetters(['getMaterials']),
		paginatedItems() {
			const start = (this.currentPage - 1) * this.items_per_page,
					end = start + this.items_per_page;
			return this.filteredItems.slice(start, end);
		},
		pageCount() {
			let l = this.filteredItems.length,
				s = this.items_per_page;
			return Math.ceil(l / s);
		},
		currentPage() {
			if(this.page > 0) {
				return Number(this.page)
			} else {
				return Number(this.page_number)
			}
		},
		filterIsOn() {
			if(this.business_type) return true
			return false
		},
		filterMaterials() {
			return this.getMaterials.filter(material => material.business_types.some(business_type => {return business_type.id == this.$route.params.business_type}))
		},
		getBusinessTypeName() {
			let name = false
			this.getMaterials.filter(material => {
				material.business_types.some(business_type => {
					if(business_type.id == this.$route.params.business_type) {
						name = business_type.type
						return business_type.type
					}
				})
			})
			return name
		},
		filteredItems() {
			const materials = this.filterIsOn ? this.filterMaterials : this.getMaterials
			const searched_materials = this.search_req != null ? materials.filter(item => item.title.toLowerCase().includes(this.search_req.toLowerCase())) : materials
			return searched_materials.sort((a, b) => {
				if(this.filter.sort_by) {
					return b.id - a.id
				} else {
					return a.id - b.id
				}
			})
			.filter((item) => {
				if(this.filter.filter_by.length > 0) {
					if(this.filter.date !== null && this.filter.date !== '') {
						return this.isEventToday(item.created, this.filter.date) ? this.filter.filter_by.includes(item.type) : false
					} else {
						return this.filter.filter_by.includes(item.type)
					}
				} else {
					if(this.filter.date !== null && this.filter.date !== '') {
						return this.isEventToday(item.created, this.filter.date) ? item : false
					} else {
						return item
					}
				}

			})
		}
	},
	methods: {
		...mapActions(['fetchAllMaterials']),
		prevPage() {
			if(this.business_type) {
				this.$router.push({
					name: 'MaterialsByBusinessTypePage',
					params: {
						page: this.currentPage-1
					}
				})
			} else {
				this.$router.push({
					name: 'MaterialsPage',
					params: {
						page: this.currentPage-1
					}
				})
			}
		},
		nextPage() {
			if(this.business_type) {
				this.$router.push({
					name: 'MaterialsByBusinessTypePage',
					params: {
						page: this.currentPage+1
					}
				})
			} else {
				this.$router.push({
					name: 'MaterialsPage',
					params: {
						page: this.currentPage+1
					}
				})
			}
		},
		atStartPage() {
			if(this.business_type) {
				this.$router.push({
					name: 'MaterialsByBusinessTypePage',
					params: {
						page: 1
					}
				})
			} else {
				this.$router.push({
					name: 'MaterialsPage',
					params: {
						page: 1
					}
				})
			}
		},
		atEndPage() {
			if(this.business_type) {
				this.$router.push({
					name: 'MaterialsByBusinessTypePage',
					params: {
						page: this.pageCount()
					}
				})
			} else {
				this.$router.push({
					name: 'MaterialsPage',
					params: {
						page: this.pageCount()
					}
				})
			}
		},
	},
	mounted() {
		this.fetchAllMaterials()
	}

}
</script>

<style scoped>

</style>
