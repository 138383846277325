<template>
  <svg :class="{right: direction === 'right', left: direction === 'left'}" width="7" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.16.5a1 1 0 0 1 .78.37l4.83 6a1 1 0 0 1 0 1.27l-5 6a1 1 0 0 1-1.54-1.28L4.7 7.5.38 2.14A1 1 0 0 1 1.16.5Z" :fill="fill"/></svg>
</template>

<script>
export default {
	name: 'IconArrow',
	props: {
		fill: {
			type: String,
			default: '#fff',
		},
		direction: {
			type: String,
			default: 'right',
			valdate: (v) => ['right', 'left'].includes(v),
		}
	}
}
</script>

<style scoped>
	.left {
		transform: rotate(180deg);
	}
</style>
